<template>
  <vue-final-modal class="modal-turn-over-wager">
    <div class="vfm__head">
      <div class="vfm__control vfm__control-left" />

      <div class="vfm__title" />

      <div class="vfm__control vfm__control-right">
        <atomic-icon id="close" class="vfm__close" @click="closeModal('turn-over-wager')" />
      </div>
    </div>

    <div class="vfm__body">
      <wallet-turn-over-wager />

      <button-base type="primary" size="md" @click="closeModal('turn-over-wager')">
        {{ getContent(props.currentLocaleData, props.defaultLocaleData, 'buttonLabel') }}
      </button-base>
    </div>
  </vue-final-modal>
</template>

<script setup lang="ts">
  import { VueFinalModal } from 'vue-final-modal';
  import type { CIModalsContent } from '~/types';

  const props = defineProps<{
    currentLocaleData: Maybe<CIModalsContent['turnOverWager']>;
    defaultLocaleData: Maybe<CIModalsContent['turnOverWager']>;
  }>();

  const { closeModal } = useModalStore();
  const { getContent } = useProjectMethods();
</script>

<style src="~/assets/styles/components/modal/turn-over-wager.scss" lang="scss" />
